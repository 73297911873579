// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_bonos.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import "./bonos.css"
import faqjson from "../../misc/bonos_related/faq.json";
import slick_items from "../../misc/bonos_related/slick_items_bonos.json";
import Slider from "react-slick";
import { SalWrapper } from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link} from "gatsby";

const pageTitle = 'Invertí en Bonos | Balanz';
const pageDesc = 'Comenzá a invertir en todos los tipos de bonos argentinos con Balanz. Mirá cómo comprar Bonos y hacer rendir tus ahorros. Contá con Asesoramiento.';

let text = `Creemos que existen tantas inversiones como inversores, <br/><span class='highlightsbold'> te presentamos Bonos.</span>`;

const BonosPage = () => {
  //const [mobile, setMobile] = useState(false);
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 7}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });

    useEffect(() => {
        const handleXs = () => {
            const isXs = window.innerWidth <= 768;
            setXs(isXs);
        };

        window.addEventListener('resize', handleXs);

        handleXs();

        return () => {
            window.removeEventListener("resize", handleXs);
        };
    }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };

  const slickBonosSettings = {
        centerMode: true,
        centerPadding: "0px",
        initialSlide: 0,
        slidesToShow: 2,
        dots: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 576,
                settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
            }
        ]
    };

    useEffect(() => {
        apiRequest
            .get("/v1/faq/7")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })
                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
  const page = (
    <>
        <Helmet>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué son los Bonos?",
                        "description": "Son emisiones de deuda, en donde el inversor es el prestamista y el emisor el deudor.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=w0jtUKEsjsI",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/bonos/",
                        "embedUrl": "https://balanz.com/inversiones/bonos/"
                    }
                `}
            </script>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Cómo invertir en Bonos?",
                        "description": "Es muy fácil, lo hacés directamente desde nuestra plataforma.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=ULZ63tXvVys",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/bonos/",
                        "embedUrl": "https://balanz.com/inversiones/bonos/"
                    }
                `}
            </script>
        </Helmet>
        <ProductBanner
          bannerContainerClassName="bannerBonos"
          bannerTrasparecy="bannerMask"
          productTitle="Bonos"
          bannerImage={Banner}
          bannerImageClassName="bonosBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
        <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué son los Bonos?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Son emisiones de deuda, en donde el inversor es el prestamista y el emisor el deudor.<br/>
                                Se denominan Títulos Públicos cuando el emisor es el Estado Nacional, los Estados Provinciales o los Municipios. Los bonos emitidos por el Estado Nacional son Bonos Soberanos, y los emitidos por Estados Provinciales o Municipales son Bonos Subsoberanos. Si el emisor es una entidad privada se denominan Obligaciones Negociables (ONs) ó Bonos Corporativos.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="bonos_invertir_1" variant="secondary" text="Invertir en Bonos" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="d-flex flex-column"
                       data-sal="fade"
                       data-sal-delay="100"
                       data-sal-duration="200"
            >
                <Row className="py-4 py-sm-3 justify-content-center order-2 order-sm-1">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/w0jtUKEsjsI"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="gx-5 gy-4 pt-3 pt-md-4 pt-lg-5 order-1 order-sm-2">
                    <Col xs={12} >
                        <p className="product-text pt-3 pt-sm-2">Son instrumentos de renta fija, ya que quien los emite fija las condiciones de emisión según sus propias necesidades de financiamiento y sus posibilidades de pago. De esta forma, existen bonos de diversas monedas, plazos, tasas y demás condiciones. Si el inversor los mantiene hasta el vencimiento sabe exactamente cuánta ganancia va a percibir.<br/><br/>

                            En Argentina los bonos se negocian en la Bolsa de Comercio de Buenos Aires, como así también en el Mercado Abierto Electrónico (MAE).
                        </p>
                    </Col>
                </Row>
                {/*<Row>
                    <div className="text-center pt-4 p-lg-3">
                        <Button variant="secondary" text="Ver Bonos"/>
                    </div>
                </Row>*/}
            </Container>
        </section>
        <section className="fullwidth tasa-renta">
            <Container>
                <Row>
                    <h2>Bonos según la Tasa de Renta</h2>
                    <Slider className="slick-tasa-renta" {...slickBonosSettings}>
                        <Col xs={12} md={6} className="slick-item-container">
                            <h3 className="block-quote bg-lt-grey p-4">Tasa<br />Fija</h3>
                            <p className="base-text-0 py-5 ">Su rendimiento está dado por la tasa estipulada en las condiciones de emisión y el descuento obtenido al momento de la compra. El riesgo está concentrado únicamente en la capacidad de pago del emisor.
                            </p>
                        </Col>
                        <Col xs={12} md={6} className="slick-item-container">
                            <h3 className="block-quote bg-lt-grey p-4">Tasa<br />Variable</h3>
                            <p className="base-text-0 py-5">Pagan interés sobre una tasa variable (Badlar, TM20, Política Monetaria, etc.). Puede ser sólo la tasa de referencia o esta más unos puntos básicos adicionales. El riesgo está concentrado en la capacidad de pago del emisor y en la fluctuación de la tasa variable que aumentará o disminuirá la renta a percibir.</p>
                        </Col>
                    </Slider>
                </Row>
            </Container>
        </section>

        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 razones para invertir en Bonos</h2>

                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>

        <section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Bonos?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button id="bonos_invertir_2" variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir en Bonos" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/ULZ63tXvVys"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 {/*<Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>*/}
             </Container>
        </section>
        <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Bonos? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'bonos-faq'} />
                </Row>
              </Container>
        </section>
        </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"bonos"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default BonosPage;
